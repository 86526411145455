import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import FindYourCPA from "../components/Repeating/FindYourCPA";
import Locations from "../components/Repeating/Locations";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

export const query = graphql`
  query SpecialtiesTemplateQuery($id: String!) {
    page: sanitySpecialties(id: { eq: $id }) {
      id
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      title
      heroImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 528)
        }
      }
      heroHeading
      _rawHeroText(resolveReferences: { maxDepth: 5 })
      introHeading
      _rawIntroText(resolveReferences: { maxDepth: 5 })
      taxServicesHeading
      taxServicesList {
        listItem
      }
    }
    blueSquare: file(
      relativePath: { eq: "global/decorative-blue-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    blueCrosses: file(
      relativePath: { eq: "global/decorative-blue-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    intro: file(relativePath: { eq: "specialty/intro.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 552)
      }
    }
    tax: file(relativePath: { eq: "specialty/tax-services.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 552)
      }
    }
  }
`;

const SpecialtiesTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;
  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {page && (
        <SearchEngineOptimization
          title={page.seoTitle}
          description={page.metaDescription}
          openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
          twitterOpenGraphImage={
            page.twitterCardImage && page.twitterCardImage.asset.url
          }
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {page && (
        <>
          <section className="mb-20 pt-10 md:mb-48 md:pt-14">
            <div className="container">
              <div className="grid items-center gap-y-18 md:grid-cols-2 md:gap-x-14 lg:gap-x-24">
                <div className="order-2 md:order-1">
                  <h1>{page.heroHeading}</h1>
                  {page._rawHeroText && (
                    <PortableText blocks={page._rawHeroText} />
                  )}
                  <ButtonSolid href="/get-a-quote/" text="Get a Quote" />
                </div>
                <div className="order-1 md:order-2">
                  <div className="relative ml-9 mr-4 md:ml-0 lg:mr-0">
                    <div className="absolute -top-4 -right-4 lg:-top-8 lg:-right-8">
                      <GatsbyImage
                        image={data.blueSquare.childImageSharp.gatsbyImageData}
                        className="w-[86px] lg:w-[160px]"
                      />
                    </div>
                    {page.heroImage && (
                      <div className="relative z-10">
                        <GatsbyImage
                          image={page.heroImage.asset.gatsbyImageData}
                          alt={page.heroHeading}
                          className="z-0 rounded-4xl"
                        />
                        <div className="absolute -left-1.5 top-1.5 z-[-10] h-full w-full rounded-3xl bg-primary-500 md:-left-3 md:top-3"></div>
                      </div>
                    )}
                    <div className="absolute -bottom-8 -left-8 lg:-bottom-18 lg:-left-18">
                      <GatsbyImage
                        image={data.blueCrosses.childImageSharp.gatsbyImageData}
                        className="w-[104px] lg:w-[192px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-20 bg-secondary-100 py-20 md:mb-32 md:py-32">
            <div className="container">
              <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div className="relative ml-1.5 md:ml-3">
                  <div className="absolute -left-1.5 -top-1.5 h-full w-full rounded-3xl bg-secondary-500 md:-left-3 md:-top-3"></div>
                  <GatsbyImage
                    image={data.intro.childImageSharp.gatsbyImageData}
                    className="z-0 w-full rounded-4xl"
                  />
                </div>
                <div>
                  <h2>{page.introHeading}</h2>
                  <PortableText blocks={page._rawIntroText} />
                  <ButtonSolid href="/get-a-quote/" text="Get a Quote" />
                </div>
              </div>
            </div>
          </section>

          <section className="mb-20 md:mb-32">
            <div className="container">
              <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div className="order-2 md:order-1">
                  <h2>{page.taxServicesHeading}</h2>
                  <ul className="styled-list-checkmark">
                    {page.taxServicesList.map((item, i) => (
                      <li key={i}>{item.listItem}</li>
                    ))}
                  </ul>
                  <ButtonSolid
                    href="/locations/"
                    text="Find a Dark Horse CPA"
                  />
                </div>
                <div className="relative order-1 mr-1.5 md:order-2 md:mr-3">
                  <div className="absolute -right-1.5 -top-1.5 h-full w-full rounded-3xl bg-primary-500 md:-right-3 md:-top-3"></div>
                  <GatsbyImage
                    image={data.tax.childImageSharp.gatsbyImageData}
                    className="z-0 w-full rounded-4xl"
                  />
                </div>
              </div>
            </div>
          </section>

          <Testimonials />
          <ValueProps />
          <FindYourCPA />
          <Locations />
          <CallToAction />
        </>
      )}
    </Layout>
  );
};

export default SpecialtiesTemplate;
